export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_EMAIL_SENT = "REGISTER_EMAIL_SENT";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILED = "CREATE_EVENT_FAILED";
export const GET_FUTURE_EVENTS = "GET_FUTURE_EVENTS";
export const GET_PAST_EVENTS = "GET_PAST_EVENTS";
export const GET_WATCHING_EVENTS = "GET_WATCHING_EVENTS";
export const GET_CREATED_EVENTS = "GET_CREATED_EVENTS";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";
export const CHANGE_EVENT_QUERY_STRING = "CHANGE_EVENT_QUERY_STRING";
export const CREATE_EVENT_COMMENT = "CREATE_EVENT_COMMENT";
export const WATCH_EVENT = "WATCH_EVENT";
export const UNWATCH_EVENT = "UNWATCH_EVENT";

export const GET_OFFERS = "GET_OFFERS";
export const GET_CREATED_OFFERS = "GET_CREATED_OFFERS";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAILED = "CREATE_OFFER_FAILED";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILED = "DELETE_OFFER_FAILED";
export const CHANGE_OFFER_QUERY_STRING = "CHANGE_OFFER_QUERY_STRING";
export const CREATE_OFFER_COMMENT = "CREATE_OFFER_COMMENT";

export const CHANGE_POST_QUERY_STRING = "CHANGE_POST_QUERY_STRING";
export const CREATE_POST_FAILED = "CREATE_POST_FAILED";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const DELETE_POST_FAILED = "DELETE_POST_FAILED";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const GET_CREATED_POSTS = "GET_CREATED_POSTS";
export const GET_POSTS = "GET_POSTS";
export const CREATE_POST_COMMENT = "CREATE_POST_COMMENT";
export const SEARCH_POSTS = "SEARCH_POSTS";

export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAILED = "UPDATE_AVATAR_FAILED";
export const UPDATE_SOCIAL_LINKS_SUCCESS = "UPDATE_SOCIAL_LINKS_SUCCESS";
export const UPDATE_SOCIAL_LINKS_FAILED = "UPDATE_SOCIAL_LINKS_FAILED";

export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const GET_FEEDBACKS = "GET_FEEDBACKS";
